import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from "./home.module.scss";
import { Link, NavLink } from "react-router-dom";

const testimonialCrsl = {
  responsive: {
    0: {
        items: 1,
    },
    768: {
        items: 2,
    },
    1261: {
        items: 3,

    }
  },
};

class Home extends React.Component {

  constructor(props){
super(props)

    this.state={
      serviceCardOneTextIsVisible:false,
      serviceCardTwoTextIsVisible:false,
      serviceCardThreeTextIsVisible:false,
      serviceCardFourTextIsVisible:false,
    }
  }

  render() {


    return (
      <div>
          <Header/>
          <div className={styles.bannerSec}>
            <div className={styles.container}>
              <div className={styles.bannerRow}>
                <div className={styles.bannerColleft}>
                  <h1>All Your Drywall Needs in one Place</h1>
                  <p>Drywall Genie allows you to easily calculate and manage how many
                    sheets you need for any given project. No more will you need to
                    keep track of a random piece of 2x4, scrap drywall, or any random
                    paper - instead, you can easily tally sheets per room, or area,
                    have the app keep track of total square footage, and easily send
                    this info to a supplier or supervisor as an email or text message.</p>
                  <a target="_blank" href="https://apps.apple.com/us/app/drywall-genie/id6453471220"><img alt="" src= {"../../images/appstore-btn.png"} /></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.drywall"><img alt="" src= {"../../images/playstore-btn.png"} /></a>

                  <div className={styles.feedBacksec}>
                    <h2>40K</h2>
                    <p>Feedback</p>
                    <ul>
                      <li><img alt="" src= {"../../images/userimg1.jpg"} /></li>
                      <li><img alt="" src= {"../../images/userimg2.jpg"} /></li>
                      <li><img alt="" src= {"../../images/userimg3.jpg"} /></li>
                      <li><img alt="" src= {"../../images/userimg4.jpg"} /></li>
                      <li><img alt="" src= {"../../images/userimg5.jpg"} /></li>
                    </ul>
                  </div>
                </div>
                <div className={styles.bannerColright}>
                  <div className={styles.banImgSec}>
                    <img alt="" src= {"../../images/banner-img2.png"} />
                  </div>
                </div>
              </div>
              <div className={styles.scrollimg}>
                <a href="#scrolling"><img alt="" src= {"../../images/scroll-img.png"} /></a>
              </div>
            </div>
          </div>


          <div className={styles.serviceSec}>
            <div className={styles.container}>
              <h2>Choose the Best App<br/> For Best <span>Services</span></h2>
              <ul>
                <li>
                  <img alt="" src= {"../../images/serviceicon2.png"} />
                  <h3>Easy & free account</h3>
                  <p>Create your free account in less than 2 minutes.   {this.state.serviceCardOneTextIsVisible&& <span>Using your Google or facebook account</span> }</p>
                  <a onClick={()=>{
                    this.setState({serviceCardOneTextIsVisible:!this.state.serviceCardOneTextIsVisible})
                  }}>Read {this.state.serviceCardOneTextIsVisible?"Less":"More"} <i className="fa fa-angle-double-right"></i></a>
                </li>
                <li>
                  <img alt="" src= {"../../images/serviceicon1.png"} />
                  <h3>Organize by job and area</h3>
                  <p>You can create multiple areas per job to keep track {this.state.serviceCardTwoTextIsVisible&& <span>of different places throughout the entire job.</span>}</p>
                  <a onClick={()=>{
                    this.setState({serviceCardTwoTextIsVisible:!this.state.serviceCardTwoTextIsVisible})
                  }}>Read {this.state.serviceCardTwoTextIsVisible?"Less":"More"} <i className="fa fa-angle-double-right"></i></a>
                </li>
                <li>
                  <img alt="" src= {"../../images/serviceicon3.png"} />
                  <h3>Tally your sheets and supplies your way</h3>
                  <p>You can type in values, or simply click to increase or  {this.state.serviceCardThreeTextIsVisible&& <span>decrease the number of sheets. The app takes care of the rest</span>}</p>
                  <a onClick={()=>{
                    this.setState({serviceCardThreeTextIsVisible:!this.state.serviceCardThreeTextIsVisible})
                  }}>Read {this.state.serviceCardThreeTextIsVisible?"Less":"More"} <i className="fa fa-angle-double-right"></i></a>
                </li>
                <li>
                  <img alt="" src= {"../../images/serviceicon4.png"} />
                  <h3>Customize it!</h3>
                  <p>Don't like what you see? You can add or remove new sheets {this.state.serviceCardFourTextIsVisible&&  <span>or new supplies either for just that job or for all new jobs</span>}</p>
                  <a onClick={()=>{
                    this.setState({serviceCardFourTextIsVisible:!this.state.serviceCardFourTextIsVisible})
                  }}>Read {this.state.serviceCardFourTextIsVisible?"Less":"More"} <i className="fa fa-angle-double-right"></i></a>
                </li>
              </ul>
            </div>
          </div>


          <div className={styles.aboutSec}>
            <div className={styles.container}>
              <div className={styles.aboutPrtRow}>
                <div className={styles.aboutLeftcol}>
                  {/* <h2>About <span>Us</span></h2>
                  <p>Drywall Genie allows users to easily record how many drywall sheets they'll
                    need per area, and the square footage of drywall they'll need. They can
                    fill out information about each job, and each job can have multiple areas.
                    These multiple areas allow the user to keep track of what sheets go where,
                    what materials go where, and then get a grand total they can send as either
                    an email or text message.</p> */}
                    <h2>About <span>us</span></h2>
                    <h3 style={{color:'#fff'}}>Why</h3>
                    <p>Let's face it, measuring drywall can take a lot of time and effort,
                      and often times board counts can get lost. With this app, you can
                      keep track of all your projects in one place</p>
                    <NavLink to="/about-us" className={styles.aboutMoreBtn}>Read More <i className="fa fa-angle-double-right"></i> </NavLink>
                </div>
                <div className={styles.aboutRightcol}>
                  <img alt="" src= {"../../images/about-img.png"} />
                </div>
              </div>
            </div>
          </div>


          <div className={styles.howItWorksSec}>
            <div className={styles.container}>
              <div className={styles.howItHdn}>
                <h2>How it <span>Works</span></h2>
              </div>
              <div className={styles.howItWorksRow}>
                <div className={styles.howItWorksImgClm}>
                  <img src={"../../images/how-it-works-img1.png"} alt="" />
                </div>
                <div className={styles.howItWorksTextClm}>
                  <h3>Create Your Job</h3>
                  <p>Creating a job is quick and easy. Simply fill out the job name
                    and date to get started. Other fields are included there for your
                    convenience to help you keep track of the necessary information.</p>
                  <div className={styles.mt40}>
                  <a target="_blank" href="https://apps.apple.com/us/app/drywall-genie/id6453471220"><img alt="" src= {"../../images/appstore-btn.png"} /></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.drywall"><img alt="" src= {"../../images/playstore-btn.png"} /></a>
                  </div>
                  {/* <a className={styles.howItWorksMoreBtn} href="#">Read More <i class="fa fa-angle-double-right"></i></a> */}
                </div>
              </div>
              <div className={styles.howItWorksRow}>
                <div className={styles.howItWorksImgClm}>
                  <img src={"../../images/how-it-works-img2.png"} alt="" />
                </div>
                <div className={styles.howItWorksTextClm}>
                  <h3>Track Your Sheets</h3>
                  <p>Here's the best part - you can create area after area of a home or
                    any job to help make sure you have exactly what you need. Type in a
                    sheet count, or click the plus or minus buttons to change how many sheets
                    of a specific size go into that area</p>
                  <p>Your area can just be one little place - like a closet - or an entire floor!
                    The level of detail you want to get into is up to you. You simply count the
                    number of sheets, and Drywall Genie lets you know how much square footage
                    you have</p>
                  <div className={styles.mt40}>
                  <a target="_blank" href="https://apps.apple.com/us/app/drywall-genie/id6453471220"><img alt="" src= {"../../images/appstore-btn.png"} /></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.drywall"><img alt="" src= {"../../images/playstore-btn.png"} /></a>
                  </div>
                  {/* <a className={styles.howItWorksMoreBtn} href="#">Read More <i class="fa fa-angle-double-right"></i></a> */}
                </div>
              </div>
              <div className={styles.howItWorksRow}>
                <div className={styles.howItWorksImgClm}>
                  <img src={"../../images/how-it-works-img3.png"} alt="" />
                </div>
                <div className={styles.howItWorksTextClm}>
                  <h3>Get a Summary</h3>
                  <p>After all that work, it would be a pain to type it all in somewhere again,
                    right? With Drywall Genie, you get a summary screen that tells you area by
                    area what sheets you need. You can send this via text or email to a client,
                    supervisor, or supplier</p>
                  <div className={styles.mt40}>
                  <a target="_blank" href="https://apps.apple.com/us/app/drywall-genie/id6453471220"><img alt="" src= {"../../images/appstore-btn.png"} /></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.drywall"><img alt="" src= {"../../images/playstore-btn.png"} /></a>
                  </div>
                  {/* <a className={styles.howItWorksMoreBtn} href="#">Read More <i class="fa fa-angle-double-right"></i></a> */}
                </div>
              </div>
            </div>
          </div>


          {/* <div className={styles.testimonialSec}>
            <div className={styles.testimonialHdnPrt}>
              <div className={styles.container}>
                <h2>What Our Client Says About Us</h2>
                <p>“Lorem Ipsum is simply dummy text of the printing and typesetting industry”</p>
              </div>
            </div>
            <div className={styles.testimonialScrollPrt}>
              <div className={styles.container}>
              <OwlCarousel className="owl-theme homeTestiCrsl" items={3} loop margin={5} autoplay ={false} autoplayTimeout={5000} slideSpeed={300} smartSpeed={2500} nav={true} dots={false} responsiveClass={true} {...testimonialCrsl} >
                <div className={styles.item}>
                  <div className={styles.testimonialItem}>
                    <div className={styles.testimonialRatingRow}>
                      <ul>
                        <li className={styles.testimonialClientImg}>
                          <img src= {"../../images/testimonial-client1.jpg"} />
                        </li>
                        <li className={styles.testimonialStarIcon}>
                          <img src= {"../../images/testimonial-star.png"} />
                        </li>
                      </ul>
                    </div>
                    <p>“Simply the best and highly competent at tax, irs, and accounting issues.”</p>
                    <h4>Shantrell Isaac</h4>
                    <h5>California</h5>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.testimonialItem}>
                    <div className={styles.testimonialRatingRow}>
                      <ul>
                        <li className={styles.testimonialClientImg}>
                          <img src= {"../../images/testimonial-client2.jpg"} />
                        </li>
                        <li className={styles.testimonialStarIcon}>
                          <img src= {"../../images/testimonial-star.png"} />
                        </li>
                      </ul>
                    </div>
                    <p>“Simply the best and highly competent at tax, irs, and accounting issues.”</p>
                    <h4>Shantrell Isaac</h4>
                    <h5>California</h5>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.testimonialItem}>
                    <div className={styles.testimonialRatingRow}>
                      <ul>
                        <li className={styles.testimonialClientImg}>
                          <img src= {"../../images/testimonial-client3.jpg"} />
                        </li>
                        <li className={styles.testimonialStarIcon}>
                          <img src= {"../../images/testimonial-star.png"} />
                        </li>
                      </ul>
                    </div>
                    <p>“Simply the best and highly competent at tax, irs, and accounting issues.”</p>
                    <h4>Shantrell Isaac</h4>
                    <h5>California</h5>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.testimonialItem}>
                    <div className={styles.testimonialRatingRow}>
                      <ul>
                        <li className={styles.testimonialClientImg}>
                          <img src= {"../../images/testimonial-client1.jpg"} />
                        </li>
                        <li className={styles.testimonialStarIcon}>
                          <img src= {"../../images/testimonial-star.png"} />
                        </li>
                      </ul>
                    </div>
                    <p>“Simply the best and highly competent at tax, irs, and accounting issues.”</p>
                    <h4>Shantrell Isaac</h4>
                    <h5>California</h5>
                  </div>
                </div>
                </OwlCarousel>
              </div>
            </div>
          </div> */}
        <Footer id="scrolling" />
      </div>
    );
  }
}
export default Home;
